import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed today from 11:30-4:30 for a video shoot.
 The only class that will be cancelled is the 12:00 class. All other
classes and open gym are on as usual.  We do need some members to be
working out in the background for the video so if you’d like to help out
please contact Daniel.`}</em></strong></p>
    <p>{`Deadlifts 6-6-6-6\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`15-Deadlifts (185/125)`}</p>
    <p>{`20-Wall Balls (20/14)`}</p>
    <p>{`then, 3 Rounds of:`}</p>
    <p>{`15-T2B`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`For total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`November 17th-18th CrossFit the Ville will be hosting a USAW
Weightlifting Level 1 Coaching Certification Course!  The Scope and
Sequence of this course includes the teaching progressions of the
Snatch, Clean & Jerk, and all associated movements.  Participants will
gain knowledge and experience of programming of training for both
competitive weightlifters and for athletes using the weightlifting
movements for strength and power development as it applies to their
chosen sport.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Lead Instructor: Mark Cannella, President and Head Coach of Columbus
Weightlifting in Columbus, Ohio.  He has been part of the lifting
community as an athlete, coach, administrator, and official for over 29
years.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This 2 day course is \\$499.  Those who complete this course will be
awarded their USAW Level 1 Coaching Certificate.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more information contact Daniel\\@crossfittheville.org or`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Mark Cannella at mark\\@columbusweightlifting.org.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      